/**
 * Created by Yosef Nussen
 * Date: 7/14/20
 */

import React from "react";
import Helmet from "react-helmet";
import moment from "moment";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Form } from "bootstrap-4-react";
import baseUrl, { authentication, getClasses, getGrades } from "../Utils";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Type } from "react-bootstrap-table2-editor";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { forEach } from "react-bootstrap/cjs/ElementChildren";
const freezeColumnStyle = {
  position: "sticky",
  left: 0,
  zIndex: 2,
  backgroundColor: "#ffffff",
};
const totalColumnStyle = {
  backgroundColor: "#FFB6C1"
};

const columns = [
  {
    dataField: "first_name",
    text: "First Name",
    sort: true,
  },
  {
    dataField: "last_name",
    text: "Last Name",
    sort: true,
  },
  {
    dataField: "absent",
    formatter: (cell, row, rowIndex) => {
      if (cell == true) {
        return (
          <div style={{ textAlign: "center" }}>
            <FaCheck color="red" size="1.5em" />
          </div>
        );
      }
      return <div></div>;
    },
    text: "Absent",
    sort: true,
    editable: false,
  },
  {
    dataField: "work",
    
    text: "Work",
    sort: true,

    editable: false,
  },
  {
    dataField: "marks",
    formatter: (cell, row, rowIndex) => {
      if (cell == true) {
        return (
          <div style={{ textAlign: "center" }}>
            <FaCheck color="green" size="1.5em" />
          </div>
        );
      }
      return <div></div>;
    },
    text: "Marks",
    sort: true,
    editable: false,
  },
  {
    dataField: "late",
    formatter: (cell, row, rowIndex) => {
      if (cell == false && row.absent == false) {
        return (
          <div style={{ textAlign: "center" }}>
            <FaCheck color="green" size="1.5em" />
          </div>
        );
      }
      if (row.absent == false) {
        return (
          <div style={{ textAlign: "center" }}>
            <FaTimes color="red" size="1.5em" />
          </div>
        );
      }
      return <div></div>;
    },
    text: "On Time",
    sort: true,
    editable: false,
  },
  {
    dataField: "attention",
    formatter: (cell, row, rowIndex) => {
      if (cell == "1") {
        return (
          <div style={{ textAlign: "center" }}>
            <FaCheck color="green" size="1.5em" />
          </div>
        );
      } else if (cell == "0") {
        return (
          <div style={{ textAlign: "center" }}>
            <FaTimes color="red" size="1.5em" />
          </div>
        );
      }
      return <div></div>;
    },
    text: "Attention",
    sort: true,
    editable: false,
  },
  {
    dataField: "note",
    text: "Notes",
    editor: {
      type: Type.TEXTAREA,
    },
  },
];

function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, "days").toDate());
  }
  return days;
}

function getWeekRange(date) {
  return {
    from: moment(date).startOf("week").toDate(),
    to: moment(date).endOf("week").toDate(),
  };
}

class WeekBehavior extends React.Component {
  state = {
    classId: "",
    date: "",
    columns: [],
    studentData: [],
    defaultGradeSelectVal: "default",
    defaultClassSelectVal: "default",
    formattedData: [],
    tableWidth: 0,
    // defaultTeacherSelectVal:"default",
    // teachers:[],
    // teacherId:"",
    // studentPoints:new Map(),
    grades: [],
    grade: "",

    classes: [],

    hoverRange: undefined,
    selectedDays: [],
  };

  constructor() {
    super();
    getGrades(this);
  }

  handleDayChange = (date) => {
    this.setState(
      {
        selectedDays: getWeekDays(getWeekRange(date).from),
      },
      this.getWeekData
    );
  };

  handleDayEnter = (date) => {
    this.setState({
      hoverRange: getWeekRange(date),
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  handleWeekClick = (weekNumber, days, e) => {
    this.setState({
      selectedDays: days,
    });
  };
  getWeekData() {
    if (this.state.selectedDays.length > 0 && this.state.classId != "") {
      let data = { classId: this.state.classId };
      data.days = [];
      this.state.selectedDays.map((day) => {
        data.days.push(moment(day).format("YYYY-MM-DD"));
      });

      data = JSON.stringify(data);

      let formData = new FormData();
      formData.append("data", data);

      ///${this.state.teacherId}/${date}/${this.state.classId}
      fetch(`${baseUrl}/week`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => {
          if (response) return response.json();
        })
        .then((res) => {
          /* res.map((item)=>{
                     item.id=parseInt(item.id);
                     item.points=parseInt(item.points)

                 })
     */
          this.setState({ studentData: res }, this.setUpTable);
        });
    }
  }
  setUpTable() {
    let columns = [
      <TableHeaderColumn row="0" colSpan="3" rowSpan="2"></TableHeaderColumn>,
    ];

    let amountOfDays = Object.keys(this.state.studentData).length;
    let newData = [];
    let dayIndex = 0;
    let teacherIndex = 0;
    let amountOfStudents = 0;
    let recordsIndex = 0;

    for (let [day, value] of Object.entries(this.state.studentData)) {
      dayIndex++;
      let amountOfPeriods = Object.keys(value).length;
      const colStyle = (day === "Totals" ? totalColumnStyle : "");

      columns.push(
        <TableHeaderColumn 
        row="0" 
        colSpan={amountOfPeriods * 4} 
        thStyle={colStyle}
        rowSpan="1">
          {day}
        </TableHeaderColumn>
      );

      for (let [teacher, value2] of Object.entries(value)) {
        teacherIndex++;
        if (dayIndex == 1 && teacherIndex == 1) {
          amountOfStudents = value2.length;
          columns.push(
            <TableHeaderColumn
              thStyle={freezeColumnStyle}
              tdStyle={freezeColumnStyle}
              width="150"
              row="2"
              dataField={`${day}_${teacher}_first_name`}
            >
              First Name
            </TableHeaderColumn>
          );
          columns.push(
            <TableHeaderColumn
              thStyle={freezeColumnStyle}
              tdStyle={freezeColumnStyle}
              width="150"
              row="2"
              dataField={`${day}_${teacher}_last_name`}
            >
              Last Name
            </TableHeaderColumn>
          );
        }

        columns.push(
          <TableHeaderColumn 
            row="1" 
            colSpan="4"
            rowSpan="1"
            thStyle={colStyle} 
            >
            {teacher}
          </TableHeaderColumn>
        );

          columns.push(
            <TableHeaderColumn
              width="100"
              row="2"
              thStyle={colStyle}
              tdStyle={colStyle}
              dataField={`${day}_${teacher}_absent`}
              dataFormat={(cell, row) => {
                if (day === 'Totals') {
                  return <div>{cell}</div>;
                } else {
                  console.log(cell);
                  if (cell === 1) {
                    console.log('in if');
                    return (
                      <div style={{ textAlign: "center" }}>
                        <FaCheck color="red" size="1.5em" />
                      </div>
                    );
                  }
                  return <div></div>;
                } 
              }}
            >
              Absent
            </TableHeaderColumn>
          );

          columns.push(
            <TableHeaderColumn
              row="2"
              width="100"
              thStyle={colStyle}
              tdStyle={colStyle}
              dataField={`${day}_${teacher}_late`}
            >
              Late
            </TableHeaderColumn>
          );

          columns.push(
            <TableHeaderColumn
              width="100"
              row="2"
              thStyle={colStyle}
              tdStyle={colStyle}
              dataField={`${day}_${teacher}_work`}
            >
              Work
            </TableHeaderColumn>
          );
          columns.push(
            <TableHeaderColumn
              row="2"
              width="100"
              thStyle={colStyle}
              tdStyle={colStyle}
              dataField={`${day}_${teacher}_marks`}
            >
              Marks
            </TableHeaderColumn>
          );

        value2.map((student, x) => {
          if (newData.length <= x) {
            student[`${day}_${teacher}_absent`] = student.absent;
            student[`${day}_${teacher}_work`] = student.work;
            student[`${day}_${teacher}_late`] = student.late;
            student[`${day}_${teacher}_marks`] = student.marks;

            student[`${day}_${teacher}_first_name`] = student.first_name;
            student[`${day}_${teacher}_last_name`] = student.last_name;
            newData.push(student);
          } else {
            newData[x][`${day}_${teacher}_absent`] = student.absent;
            newData[x][`${day}_${teacher}_work`] = student.work;
            newData[x][`${day}_${teacher}_late`] = student.late;
            newData[x][`${day}_${teacher}_marks`] = student.marks;
          }
          recordsIndex++;
        });
      }
    }

    this.setState({
      columns: columns,
      formattedData: newData,
      tableWidth: 300 * teacherIndex + 800,
    });

    console.log('New Data is: ', newData);
    console.log('Student data in state: ', this.state.studentData);
  }

  render() {
    const { hoverRange, selectedDays } = this.state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6],
    };
    return (
      <div className="SelectedWeekExample">
        <Breadcrumb>
          <Breadcrumb.Item href="home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Behavior</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: "25px" }}>
          <div className="row">
            <div className="col-sm-6">
              <DayPicker
                selectedDays={selectedDays}
                showWeekNumbers
                showOutsideDays
                modifiers={modifiers}
                onDayClick={this.handleDayChange}
                onDayMouseEnter={this.handleDayEnter}
                onDayMouseLeave={this.handleDayLeave}
                onWeekClick={this.handleWeekClick}
              />
              {selectedDays.length === 7 && (
                <div>
                  {moment(selectedDays[0]).format("LL")} –{" "}
                  {moment(selectedDays[6]).format("LL")}
                </div>
              )}
            </div>

            <div className="col-sm-6">
              <div className="col-sm-12">
                <Form.CustomSelect
                  lg
                  mb="3"
                  value={this.state.defaultGradeSelectVal}
                  onChange={(v) => {
                    if (v.target.value != "default") {
                      this.setState({
                        grade: v.target.value,
                        defaultGradeSelectVal: v.target.value,
                        defaultClassSelectVal: "default",
                      });
                      getClasses(v.target.value, this);
                    }
                  }}
                >
                  {this.state.grades.map((x, y) => (
                    <option value={x.id} key={y}>
                      {x.name}
                    </option>
                  ))}
                  {this.state.defaultGradeSelectVal == "default" && (
                    <option value="default">Choose Grade</option>
                  )}
                </Form.CustomSelect>
              </div>
              <div className="col-sm-12" style={{ paddingTop: "10px" }}>
                {this.state.grade != "" && (
                  <Form.CustomSelect
                    lg
                    mb="3"
                    value={this.state.defaultClassSelectVal}
                    onChange={(v) => {
                      if (v.target.value != "default") {
                        this.setState(
                          {
                            classId: v.target.value,
                            defaultClassSelectVal: v.target.value,
                          },
                          this.getWeekData()
                        );
                      }
                    }}
                  >
                    {this.state.classes.map((x, y) => (
                      <option value={x.id} key={y}>
                        {x.class}
                      </option>
                    ))}
                    {this.state.defaultClassSelectVal == "default" && (
                      <option value="default">Choose Class</option>
                    )}
                  </Form.CustomSelect>
                )}
              </div>
            </div>
          </div>
          {/* <div style={{width:"100%",overflow:"scroll"}}>
                <div style={{width:4500}}>
                <BootstrapTable >
                    <TableHeaderColumn row='0' colSpan='10' rowSpan='1' >Sunday</TableHeaderColumn>
                    <TableHeaderColumn row='0' colSpan='9' rowSpan='1' >Monday</TableHeaderColumn>
                    <TableHeaderColumn row='0' colSpan='9' rowSpan='1' >Tuesday</TableHeaderColumn>
                    <TableHeaderColumn row='0' colSpan='9' rowSpan='1' >Wednsday</TableHeaderColumn>
                    <TableHeaderColumn row='0' colSpan='9' rowSpan='1' >Thursday</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='1' rowSpan='1' ></TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >Math</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >Science</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >History</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >Math</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >Science</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >History</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >Math</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >Science</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >History</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >Math</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >Science</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >History</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >Math</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >Science</TableHeaderColumn>
                    <TableHeaderColumn row='1' colSpan='3' rowSpan='1' >History</TableHeaderColumn>

                    <TableHeaderColumn isKey row='2' dataField='id'>ID</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='absent'>Absent</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='work'>Work</TableHeaderColumn>
                    <TableHeaderColumn row='2' dataField='marks'>Marks</TableHeaderColumn>

                </BootstrapTable>
                </div>
                </div>

                style={{width:this.state.tableWidth}}
                */}
          <div style={{ width: "100%", overflow: "scroll" }}>
            <div>
              <BootstrapTable
                height={700}
                hover
                data={this.state.formattedData}
              >
                <TableHeaderColumn isKey row="2" dataField="id">
                  ID
                </TableHeaderColumn>
                {this.state.columns.length > 0 && [...this.state.columns]}
              </BootstrapTable>
            </div>
          </div>
        </div>

        <Helmet>
          <style>{`
            .SelectedWeekExample .DayPicker-Month {
              border-collapse: separate;
            }
            .SelectedWeekExample .DayPicker-WeekNumber {
              outline: none;
            }
            .SelectedWeekExample .DayPicker-Day {
              outline: none;
              border: 1px solid transparent;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange {
              background-color: #EFEFEF !important;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange {
              background-color: #fff7ba !important;
              border-top-color: #FFEB3B;
              border-bottom-color: #FFEB3B;
              border-left-color: #fff7ba;
              border-right-color: #fff7ba;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeStart {
              background-color: #FFEB3B !important;
              border-left: 1px solid #FFEB3B;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
              background-color: #FFEB3B !important;
              border-right: 1px solid #FFEB3B;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
            .SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
              border-radius: 0 !important;
              color: black !important;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
              border-radius: 0 !important;
            }
          `}</style>
        </Helmet>
      </div>
    );
  }
}

export default WeekBehavior;
